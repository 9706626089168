<script setup>
    import { getOrCreateDataObject, deleteDataObject, getOrCreateProcedure } from 'o365.vue.ts'
    import Context from 'o365.modules.Context.ts';
    import alert from 'o365.controls.alert.ts';
    import { onUnmounted, ref, reactive } from 'vue'
    import $t from "o365.modules.translate.ts";
    
    import OOrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';

    const props = defineProps({
        "PreselectedSource": {
            default: { ID: null, Name: null },
            type: Object
        }
    });
    const emits = defineEmits(["newRamsID"])
    const context = ref(Context);

    const procAddRams = new getOrCreateProcedure({ id:"procAddRams", procedureName: "astp_RAMS_RiskAdd" });

    const initialNewRamsObj = {
        title: "", 
        type_name: "",
        type_id: null,
        orgunit_name: "",
        orgunit_id: null,
        status_name: "", 
        status_id: null,
        source_name: "",
        source_id: null
    }
    const newRamsObj = reactive({...initialNewRamsObj})  
    const newRamsDlg = ref(null);
    const sourceWhereClause = ref(`OrgUnitIdPath LIKE '${context.value.idPath}'`);
    const sourceLimitContext = ref(true);
    const preselectedSourceDefined = ref(props.PreselectedSource.ID !== null ? true : false);

    const local_dsTypesLkp = getOrCreateDataObject({
        id: 'dsTypesLkp',
        viewName: 'atbv_RAMS_Types',
        maxRecords: 25,
        whereClause: "ShowInElements = 1",
        fields:
            [
                {name: "ID", type: "int" }, 
                {name: "Name", type: "string" }
            ]
    });

    const local_dsStatusLkp = getOrCreateDataObject({
        id: 'dsStatusLkp',
        viewName: 'atbv_RAMS_Statuses',
        maxRecords: 25,
        fields:
            [
                {name: "Name", type: "string" },
                {name: "ID", type: "int" }, 
                {name: "DefaultChoice", type: "boolean"}
            ]
    });

    const local_dsSourcesLkp = getOrCreateDataObject({
        id: 'dsSourcesLkp',
        viewName: 'atbv_RAMS_Sources',
        maxRecords: 25,
        fields:
            [
                {name: "Name", type: "string" },
                {name: "ID", type: "int" }
            ]
    });

    const setDefaultStatus = async () => {
        await local_dsStatusLkp.load().then(() => {
            const defaultStatus = local_dsStatusLkp.data.find(item => item.DefaultChoice) !== undefined ? local_dsStatusLkp.data.find(item => item.DefaultChoice) : null;
            if(defaultStatus !== null) {
                newRamsObj.status_id = defaultStatus.ID;
                newRamsObj.status_name = defaultStatus.Name;
            }
        });
    }

    const toggleSourceContext = (pLimitContext) => {
        if(pLimitContext) {
            sourceWhereClause.value = `OrgUnitIdPath LIKE '${context.value.idPath}'`;
        } else {
            sourceWhereClause.value = "";
        }

        local_dsSourcesLkp.recordSource.whereClause = sourceWhereClause.value;
        local_dsSourcesLkp.load();
    }

    const createNewRams = async () => {
        let alertMsg = null;
        // Validation on status and org might be removed since its "noClear" - an extra check doesnt hurt
        if(!newRamsObj.title) { alertMsg = $t("Title is required."); }
        if(!newRamsObj.type_id) { alertMsg = $t("Type is required."); }
        if(!newRamsObj.orgunit_id) { alertMsg = $t("Org. unit is required.");  }
        if(!newRamsObj.status_id) { alertMsg = $t("Status is required.");  }
        if(alertMsg) {
            alert(alertMsg, 'warning', {autohide: true, delay: 3000});
            return;
        }

        await procAddRams.execute({
            Title: newRamsObj.title,
            OrgUnit_ID: newRamsObj.orgunit_id,
            Status_ID: newRamsObj.status_id,
            Type_ID: newRamsObj.type_id,
            Source_ID: newRamsObj.source_id
        }).then((response) => {
            if(response.Table[0]["ID"]) {
                alert($t('Risk was successfully created.'), 'success', { autohide: true, delay: 3000});
                emits("newRamsID", response.Table[0]["ID"]);
                newRamsDlg.value.hide();
            } else {
                alert($t('Something went wrong, try again.'), 'danger', { autohide: true, delay: 3000});
            }
        })
    }

    const onShownDialog = () => {
        newRamsObj.orgunit_id = context.value.id; 
        newRamsObj.orgunit_name = context.value.name; 

        if(props.PreselectedSource.ID !== null) {
            newRamsObj.source_id = props.PreselectedSource.ID;
            newRamsObj.source_name = props.PreselectedSource.Name;
        }

        setDefaultStatus();
    }

    const resetOnClose = () => {
        Object.assign(newRamsObj, initialNewRamsObj);
    }

    onUnmounted(() => {
        deleteDataObject(local_dsTypesLkp.id, local_dsTypesLkp.appId)
        deleteDataObject(local_dsStatusLkp.id, local_dsStatusLkp.appId)
        deleteDataObject(local_dsSourcesLkp.id, local_dsSourcesLkp.appId)
    })
</script>

<template>
    <OModal 
        name="newRamsDlg" 
        ref="newRamsDlg" 
        @shown="onShownDialog"
        @hidden="resetOnClose"
    > 
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-titel">{{ $t('New Risk') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="col-12">
                            <label class="form-label">{{ $t('Title') }} *</label>
                            <input class="form-control" v-model="newRamsObj.title">
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label class="form-label">
                                    {{ $t('Type') }} *
                                </label>
                                <ODataLookup
                                    class="form-select"
                                    :data-object="local_dsTypesLkp"
                                    :bind="sel => { newRamsObj.type_id = sel.ID; newRamsObj.type_name = sel.Name; }"
                                    v-model="newRamsObj.type_name"
                                >
                                    <OColumn width="75" field="ID" />
                                    <OColumn width="250" field="Name" :headerName="$t('Name')" />
                                </ODataLookup>
                            </div>
                            <div class="col">
                                <label class="form-label">{{ $t('Org. unit') }} *</label>
                                <OOrgunitsLookup 
                                    :bind="sel => { newRamsObj.orgunit_id = sel.ID, newRamsObj.orgunit_name = sel.OrgUnit; }" 
                                >
                                    <template #orgunit>
                                        <input type="text" class="form-select form-control" v-model="newRamsObj.orgunit_name">
                                    </template>
                                </OOrgunitsLookup>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label class="form-label">{{ $t('Status') }} *</label>
                                <ODataLookup
                                    class="form-select form-control" 
                                    :data-object="local_dsStatusLkp" 
                                    :bind="sel => { newRamsObj.status_id = sel.ID, newRamsObj.status_name = sel.Name }" 
                                    disableRecent
                                    disablePinned
                                    :noClear="true"
                                    v-model="newRamsObj.status_name"
                                >
                                    <OColumn field="ID" width="75" />
                                    <OColumn field="Name" :headerName="$t('Name')" width="175"/>
                                </ODataLookup>
                            </div>
                            <div class="col">
                                <label class="form-label">{{ $t('Source') }}</label>
                                <ODataLookup
                                    class="form-select form-control" 
                                    :disabled="preselectedSourceDefined"
                                    :data-object="local_dsSourcesLkp" 
                                    :bind="sel => { newRamsObj.source_id = sel.ID, newRamsObj.source_name = sel.Name }" 
                                    v-model="newRamsObj.source_name"
                                    disableRecent
                                    disablePinned
                                    :whereClause="sourceWhereClause"
                                >
                                    <template #toolbarActions>
                                        <div class="form-check">
                                            <input class="form-check-input" id="dsSourcesLkp_RestrictToContext" type="checkbox" v-model="sourceLimitContext" @change="toggleSourceContext(sourceLimitContext)">
                                            <label class="form-check-label" for="dsSourcesLkp_RestrictToContext">{{ $t("Restrict to Context") }}</label>
                                        </div>
                                    </template>
                                    <OColumn field="ID" width="75" />
                                    <OColumn field="Name" :headerName="$t('Name')" width="175"/>
                                </ODataLookup>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
                    <button class="btn btn-primary" @click="createNewRams">{{ $t('Add risk') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>